import { Options, Vue } from "vue-class-component";
import { useToast } from "vue-toastification";

@Options({
    name: "app-base-function",
})
export default class BaseFunction extends Vue {
    protected toast = useToast();

    //获取用户信息
    get authUser() {
        return this.$store.getters["auth/userInfo"] ? this.$store.getters["auth/userInfo"] : null;
    }

    //获取页面角色
    get pageRole() {
        return this.$store.getters["auth/pageRole"] && this.$store.getters["auth/pageRole"][this.$route.path] ? this.$store.getters["auth/pageRole"][this.$route.path] : null;
    }

    //权限认证
    protected Auth(path: string): boolean {
        return this.pageRole && this.pageRole.includes(path);
    }
}

import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import ElementResizeDetectorMaker from "element-resize-detector";

@Options({
    name: "app-monitor-dashboard-running-state-mixins",
    props: {
        modelValue: {
            type: Object,
            default: [],
        },
    },
    emits: ["update:modelValue"],
})
export default class Mixins extends BaseFunction {
    public modelValue: any;
    public option: any = {};
    public myEcharts: any = null;
    public chartDom: any = null;
    public chart: any = null;
    public erd: any = null;

    //数据
    get eChartData(): any {
        return this.modelValue;
    }

    set eChartData(val: any) {
        this.$emit("update:modelValue", val);
    }

    public unmounted() {
        if (this.myEcharts) this.myEcharts.dispose;
    }

    public beforeDestroy() {
        if (this.erd && this.chartDom) this.erd.uninstall(this.chartDom);
    }

    public async init(echarts: any, dom: HTMLElement, renderer = "svg"): Promise<void> {
        this.myEcharts = echarts;
        this.chartDom = dom;
        await this.$nextTick(() => {
            this.myEcharts = echarts;
            this.initErd(echarts, dom, renderer);
            this.initChart(echarts, dom, renderer);
            Promise.resolve();
        }).catch((error) => {
            Promise.reject(error);
        });
    }

    public initErd(echarts: any, dom: HTMLElement, renderer = "svg") {
        this.erd = this.erd ? this.erd : ElementResizeDetectorMaker();
        this.erd.listenTo(this.chartDom, (el: HTMLElement) => {
            this.initChart(echarts, dom, renderer);
        });
    }

    public initChart(echarts: any, dom: HTMLElement, renderer = "svg") {
        if (this.chart) {
            this.chart.clear();
            this.chart.resize();
        } else {
            if (renderer === "svg") {
                this.chart = this.myEcharts.init(dom, null, {
                    renderer: "svg",
                });
            } else {
                this.chart = this.myEcharts.init(dom);
            }
        }
        this.chart.setOption(this.option);
    }
}
